/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
          marginBottom: `150px`
        }}
      >
        <main style={{ paddingTop: `100px`, }}>{children}</main>

      </div>
      <footer style={{ bottom: `0px`, position: `fixed`, width: `100%`, backgroundColor: `#f5f5f7`, color: `#1d1d1f` }}>
        <center>
          <p style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `1.0875rem 1.45rem`,
          }}> © TimeNamic {new Date().getFullYear()}</p>
        </center>

      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
